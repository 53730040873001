.amount-input {
  display: flex;
  flex: 1;
  font-size: 60px;
  font-weight: 400;
  padding: 0 10px 0 27px;
  border: 1px solid #BAB8B8;
  max-width: 92px;
}
@media (max-width: 760px) {
  .amount-input {
    font-size: 40px;
    max-width: 40px;
    padding: 0;
    padding-left: 8px;
  }
}

.beets-enter-amount-form-title {
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 2px;
  text-align: center;
}

.beets-enter-amount-form-subtitle {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 20px;
}

.beets-enter-amount-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  row-gap: 20px;
}

  .beets-enter-amount-form button {
    display: flex;
    flex: 1;
    background-color: #050E2C;
    color: #fff;
    font-size: 24px;
    border: unset;
    text-align: center;
    padding: 15px;
    justify-content: center;
    cursor: pointer;
    min-width: 540px;
  }
  @media (max-width: 760px) {
    .beets-enter-amount-form button {
      min-width: fit-content;
    }
  }

  .beets-enter-amount-form button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media (max-width: 760px) {
    .beets-enter-amount-form button:disabled {
      min-width: fit-content;
    }
  }

  .beets-enter-amount-form-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    flex-wrap: wrap;     
  }
  @media (max-width: 760px) {
    .beets-enter-amount-form-row {
      column-gap: 10px;
    }
  }

  .beets-enter-amount-form-dollar,
  .beets-enter-amount-form-dot {
    font-family: Circular Std;
    font-size: 60px;
    font-weight: 700;
    line-height: 76px;
    text-align: left;
  }
  @media (max-width: 760px) {
    .beets-enter-amount-form-dollar,
    .beets-enter-amount-form-dot {
      font-size: 40px;
    }
  }

  .beets-enter-amount-form-error {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: -20px;
    color: red;
  }
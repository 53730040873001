@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

.dpa {
    font-family: 'Manrope';
    --mainBlue: #020FBA;
    --highlightColor: #76ECA5;
}

.dpa p {
    margin: 0;
}

.dpa__container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 30px 0;
}

@media (max-width: 768px)  {
    .dpa__container {
        padding-top: 50px;
    }
}

.dpa__header {
    margin-bottom: 80px;
}

@media (max-width: 768px)  {
    .dpa__header {
        margin-bottom: 50px;
    }
}

.dpa__logo__image {
    text-align: center;
    margin-bottom: 12px;
}

.dpa__logo__caption {
    font-weight: 400;
    font-size: 13;
    line-height: 110%;
}

.dpa__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
}

.dpa__body p {
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    color: var(--mainBlue);
    margin-bottom: 15px;
}

.dpa__body__amount {
    font-weight: 500;
    font-size: 64px;
    line-height: 100%;
    text-align: center;
    color: var(--mainBlue);
}   

.dpa__precard__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    text-align: center;
    color: #000000;
    margin: 45px auto 55px;
}

.dpa__cards__container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 70px;
    row-gap: 40px;
}

.dpa__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 20px 0;
    background: #F7F7F7;
    border-radius: 20px;
    min-width: 250px;
}

.dpa__card__header {
    text-align: center;
    background: #D8D8D8;
    border-radius: 20px 20px 0px 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    color: #000000;
    margin-bottom: 35px;
    padding: 15px 22px;
    width: 100%;
}

.dpa__card__body {
    display: flex;
    flex-direction: column;
    padding: 0 22px;
    min-height: 210px;
    margin-bottom: 30px;
}

.dpa__card__amount {
    font-weight: 400;
    text-align: center;
    font-size: 36px;
    color: var(--mainBlue);
}

.dpa__card__amount__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: #000000;
    text-align: center;
    margin-bottom: 10px;
}

.dpa__card__amount__descr {
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;
    text-align: center;
    padding: 10px 10px;
    background-color: #D8D8D8;
    border-radius: 8px;
}

.dpa__card__spacer {
    display: flex;
    flex: 1;
}

.dpa__card__details {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 30px;
    justify-content: space-between;
}

.dpa__card__details__block {
    display: flex;
    flex-direction: column;
}

.dpa__card__details__apr {
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: var(--mainBlue);
}

.dpa__card__details__term {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #000000;
}

.dpa__card__footer {
    display: flex;
    flex: 1;
    padding: 0 20px;
    width: 100%;
}

.dpa__card__button {
    width: 100%;
    background: var(--mainBlue);
    border-radius: 10px;
    text-align: center;
    color: #FFF;
    padding: 10px 0;
    font-weight: 500;
    font-size: 16px;
    opacity: 1;
    transition: opacity linear .1s;
}
    .dpa__card__button:hover {
        transition: opacity linear .1s;
        cursor: pointer;
        opacity: .85;
    }    

.dpa__card--highlight
    .dpa__card__header {
        background-color: var(--highlightColor);
}

.dpa__card--highlight 
    .dpa__card__amount__descr {
        background-color: var(--highlightColor);
}

.dpa__piggybank {
    margin-bottom: 60px;
}

.dpa__body--narrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 470px;
 }

 .dpa__outcome {
    display: flex;
    flex-direction: column;
    min-width: 350px;
    border: 1px solid #E4E4E4;
    border-radius: 20px;
    padding: 22px 20px;
    row-gap: 20px;
    margin-top: 50px;
 }

 .dpa__outcome__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
 }

 @media (max-width: 768px)  {
    .dpa__outcome {
        min-width: 300px;
    }

    .dpa__outcome__row {
        font-size: 16px;
    }
}

 .dpa__body__button {
    text-align: center;
    min-width: 200px;
    background: var(--mainBlue);
    border-radius: 4px;
    font-weight: 500;
    font-size: 20px;
    color: #FFF;
    padding: 10px 0;
    margin: 50px auto;
    transition: opacity linear .1s;
 }

    .dpa__body__button:hover {
        transition: opacity linear .1s;
        cursor: pointer;
        opacity: .85;
    }

.dpa__body__result {
    font-weight: 400;
    font-size: 12.3878px;
    line-height: 150%;
    text-align: center;
    color: #242640;
}

.beets-subscription-form-title {
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 2px;
  text-align: center;
}

.beets-subscription-form-subtitle {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 8px;
}

.beets-subscription-form {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  row-gap: 20px;
}
  .beets-subscription-form input[type="text"], .beets-subscription-form input[type="password"] {
    display: flex;
    flex: 1;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 16px;
    font-weight: 400;
    padding: 20px;
    border: 1px solid #BAB8B8;
  }

  .beets-subscription-form button {
    display: flex;
    flex: 1;
    background-color: #a58852;
    color: #fff;
    font-size: 24px;
    border: unset;
    text-align: center;
    padding: 15px;
    justify-content: center;
    cursor: pointer;
  }

  .beets-subscription-form button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .beets-subscription-form .beets-subscription-form-line1 {
    display: flex;
    width: 100%;
    flex-direction: row;
    column-gap: 40px;
    flex-wrap: wrap;
    gap: 20px;
  }
.beets-subscription-not-found-title {
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 2px;
  text-align: center;
}

.beets-subscription-not-found-subtitle {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 20px;
}

.beets-subscription-not-found-button {
  display: flex;
  flex: 1;
  background-color: #000;
  color: #fff;
  font-size: 24px;
  border: unset;
  text-align: center;
  padding: 15px;
  justify-content: center;
  cursor: pointer;
  margin-top: 100px;
  min-width: 540px;
}
@import 'antd/dist/antd.css';
@import 'bootstrap/dist/css/bootstrap.css';

:root {
    --main-color: #3951B5;
    --main-backgroundcolor: #fbfbfb;
    --steps-wrapper-background-color: #fbfbfb;
    --steps-background-active-color: #ffffff;
    --steps-background-inactive-color: #ffffff;
    --steps-wrapper-border-color: #6276E4;
    --main-font: CircularStd;
    --headers-font: ClearfaceITCbyBT;
    --inputs-color: #ffffff;
    --inputs-border-color: #dee3ee;
    --inputs-label-color: #666666;
    --inactive-button-color: #788dba;
    --result-block-label-color: #242640;
    --info-block-background-color: #EEEFF3;
    --info-block-border-color: #dee3ee;
    --accept-button-background-color: #dee3ee;
    --accept-button-color: #000000;

    --pay-option-background: rgba(238, 239, 243, 0.3);
    --pay-option-selected-background: rgba(238, 239, 243, 1);
    --pay-option-block-border-color: #DEE3EE;
    --pay-option-old-values-color: #979797;
    --pay-option-detail-block-background-color: #ffffff;
    --pay-option-detail-block-border-color: #DEE3EE;

    --flow-3-background-color: #ffffff;
    --flow-3-top-menu-background-color: #000000;
    --flow-3-top-menu-text-color: #ffffff;
    --flow-3-paymnet-option-background-color: #FAFAFA;
    --flow-3-paymnet-option-border-color: #DEE3EE;

    --flow-3-popup-overlay-color: rgba(0, 0, 0, 0.6);
    --flow-3-popup-background-color: #ffffff;
    --flow-3-popup-button-background-color: #000000;
    --flow-3-popup-button-text-color: #ffffff;

    --flow-5-background-color: #DEE3EE;
    --flow-5-popup-overlay-color: rgba(0, 0, 0, 0.6);
    --flow-5-popup-background-color: #ffffff;
    --flow-5-popup-button-background-color: #000000;
    --flow-5-popup-button-text-color: #ffffff;

}

@font-face {
    font-family: CircularStd;
    src: url("./assets/fonts/CircularStd-Book.otf") format("opentype");
}
@font-face {
    font-family: CircularStd;
    font-weight: bold;
    src: url("./assets/fonts/CircularStd-Black.otf") format("opentype");
}
@font-face {
    font-family: ClearfaceITCbyBT;
    src: url("./assets/fonts/ClearfaceITCbyBT-Regular.otf") format("opentype");
}
@font-face {
    font-family: ClearfaceITCbyBT;
    font-weight: bold;
    src: url("./assets/fonts/ClearfaceITCbyBT-Bold.otf") format("opentype");
}


@font-face {
    font-family: Gotham;
    src: url("./assets/fonts/gotham/GothamMedium.ttf") format("truetype");
}
@font-face {
    font-family: Gotham;
    font-weight: bold;
    src: url("./assets/fonts/gotham/GothamBold.ttf") format("truetype");
}


html, body {
    background-color: var(--main-backgroundcolor) !important;
    font-family: var(--main-font), serif;
}

h2 {
    font-family: var(--headers-font), serif;
}

.overlay-box {
    position: fixed;
    top: 0;
    left: 0;
    background: #ffffff;
    opacity: 0.7;
    z-index: 1000;
    width: 100%;
    height: 100vh;
}
*:focus {
    outline: none;
}
.spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    height:60px;
    width:60px;
    margin:0 auto;
    -webkit-animation: rotation .6s infinite linear;
    -moz-animation: rotation .6s infinite linear;
    -o-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left:6px solid rgba(0,174,239,.15);
    border-right:6px solid rgba(0,174,239,.15);
    border-bottom:6px solid rgba(0,174,239,.15);
    border-top:6px solid rgba(0,174,239,.8);
    border-radius:100%;
}
@-webkit-keyframes rotation {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
    from {-moz-transform: rotate(0deg);}
    to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
    from {-o-transform: rotate(0deg);}
    to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
}

.wizard {
    display: block;
}

.wizard a {
    position: relative;
    vertical-align: middle;
    display: table-cell;
    min-width: 15%;
    margin-left: 0.25%;
    text-align: center;
    text-decoration: none;
    color: var(--main-color);
    background: var(--steps-wrapper-background-color);
    cursor: pointer;
    font-size: 0.4em;
    padding: 12px 1px 11px 18px;
    opacity: 0.2;
}

.wizard-step-logo-wrapper {
    width: 100%;
    border: 2px solid var(--steps-wrapper-border-color);
    border-radius: 4px;
    height: 10vw;
    margin-right: 20px;
    background: var(--steps-background-inactive-color);
    display: flex;
    align-content: center;
    align-items: center;
    vertical-align: middle;
    flex-wrap: wrap;
    position: relative;
}
.wizard-step-logo {
    width: 45%;
    height: 45%;
    background-repeat: no-repeat;
    margin: 0 auto;
    background-size: contain;
    background-position: center;
}
.wizard-step-logo-loan-request {
    background-image: url('./assets/images/step-loan-request.svg');
}
.wizard-step-logo-personal-information {
    background-image: url('./assets/images/step-personal-information.svg');
}
.wizard-step-logo-income {
    background-image: url('./assets/images/step-income.svg');
}
.wizard-step-logo-bank-information {
    background-image: url('./assets/images/step-bank-information.svg');
}
.wizard-step-logo-payments {
    background-image: url('./assets/images/step-payment.svg');
}
.wizard-step-logo-description {
    text-align: center;
    margin-top: 1vw;
    width: 100%;
    font-size: 1vw;
}
.wizard-step-logo-done {
    position: absolute;
    width: 2vw;
    height: 2vw;
    background-image: url('./assets/images/step-check.svg');
    background-size: contain;
    background-repeat: no-repeat;
    top: 0.75vw;
    right: 0.75vw;
}

.break {
    flex-basis: 100%;
    height: 0;
}

@media (min-width: 768px) {
    .wizard a {
        font-size: 0.7em;
        padding: 10px 12px 10px;
        display: inline-block;
    }
}
@media (min-width: 992px) {
    .wizard a {
        font-size: 0.8em;
    }
}
@media (min-width: 1200px) {
    .wizard a {
        font-size: 1em;
    }
}

.wizard a:hover {
    text-decoration: none;
    color: var(--main-color);
}
.wizard a:first-child {
    margin-left: 0;
}

.wizard a:first-child:before,
.wizard a:last-child:after {
    border: none;
}
.wizard a.wizard-done {
    color: var(--main-color);
    opacity: 1;
}
.wizard a.current {
    color: var(--main-color);
    opacity: 1;
}

.wizard a.current .wizard-step-logo-wrapper {
    -webkit-box-shadow: 5px 5px 0px 0px var(--main-color);
    box-shadow: 5px 5px 0px 0px var(--main-color);
}

.member-onboarding-right-image {
    position: fixed;
    width: 100%;
    height: 100vh;
}

.member-onboarding-form-wrapper > .row {
    margin-left: 0;
    margin-right: 0;
}

.member-onboardig-datepicker, .member-onboardig-select {
    width: 100%;
}

.ant-input {
    border-color: var(--inputs-border-color);
}

.ant-input-group-addon {
    /*padding-bottom: 4px;*/
    color: var(--main-color);
    font-size: 21px;
    background-color: var(--inputs-color);
    width: 45px;
    border-color: var(--inputs-border-color);
}

.member-onboarding-long-text {
    height: 15vh;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid #cccccc;
    padding: 10px;
}

.widget-wrapper {
    top: 50% !important;
    left: 42% !important;
}

.member-onboardig-api-script {
    background-color: #454545;
    color: #ffffff;
}

.field-validation-failed {
    color: #ff0000;
    font-size: 0.8em;
}
.form-input-label label {
    color: var(--inputs-label-color);
    margin-bottom: 8px;
    font-size: 15px;
}

/*.ant-input {
    border-radius: 25px;
}*/

.slider-input .ant-input-number {
    margin: 0px 0px 0px 16px !important;
    float: right;
}

.ant-slider-track {
    background-color: var(--main-color);
    height: 16px;
    border-radius: 16px;
    margin-top: -5px;
}
.ant-slider:hover .ant-slider-track {
    background-color: var(--main-color);
}
.ant-slider-handle {
    border: 7px solid var(--inputs-color);
    background-color: var(--main-color);
    height: 28px;
    width: 28px;
    margin-top: -11px;
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.36);
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.36);
}
.ant-slider-dot {
    display: none;
}
.ant-btn-primary {
    background-color: var(--main-color);
    font-size: 16px;
    width: 200px;
    height: 52px;

}
.ant-btn-primary:hover {
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
}
.prev-button {
    background-color: var(--inactive-button-color);
    border: 1px solid var(--inactive-button-color);
    margin: 10px 12px;
}

.prev-button:hover {
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
}

.ant-statistic-title {
    font-size: 14px;
    color: var(--result-block-label-color);
}
.ant-statistic-content-prefix, .ant-statistic-content-value, .ant-statistic-content-suffix {
    color: var(--main-color);
    font-size: 20px;
}

.info-block-grey {
    background-color: var(--info-block-background-color);
    border: 1px solid var(--info-block-border-color);
    display: flex;
}

.info-block-grey-logo {
    display: inline-flex;
}
.logo-payroll {
    width: 300px;
    height: 190px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    display: inline-flex;
    margin-left: 20px;
    margin-top: 20px;
}
.logo-payroll-type1 {
    background-image: url('./assets/images/payroll-block-logo.svg');
}
.logo-payroll-type2 {
    background-image: url('./assets/images/no-more-forms.svg');
    width: 260px;
    height: 170px;
}
.info-block-grey-content {
    margin-left: 50px;
    text-align: left;
    margin-right: 30px;
}
.info-block-grey-content-title {
    font-size: 18px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.info-block-grey-content-title-type2 {
    font-family: var(--headers-font);
    color: var(--main-color);
    font-size: 18px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.info-block-grey-content-message {
    font-size: 14px;
    margin-top: 10px;
}
.info-block-grey-content-button {
    margin-top: 20px;
}

.info-block-grey-content-button .connect-payroll-button {
    background-color: var(--main-color);
    font-size: 14px;
    width: 168px;
    height: 44px;
    border-radius: 4px;
    border: 1px solid var(--main-color);
    margin-right: 10px;
}
.info-block-grey-content-button .accept-button {
    background: var(--accept-button-background-color);
    color: var(--accept-button-color);
    border: 1px solid var(--main-color);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;    
    width: 168px;
    height: 44px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.congratulations-logo {
    width: 22vw;
    height: 12vw;
    background-image: url('./assets/images/congratulations-logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
}
.congratulations-text {
    font-size: 18px;
}

.pay-option {
    background-color: var(--pay-option-background);
    padding: 10px 40px;
    border: 1px solid var(--pay-option-block-border-color);
    border-radius: 4px;
}
.pay-option.active {
    background-color: var(--pay-option-selected-background);
    border: 1px solid var(--main-color);
}
.pay-option-detail-old-value {
    color: var(--pay-option-old-values-color);
    text-decoration: line-through;
    font-size: 14px;
    text-align: center;
}
.pay-option-detail-new-value {
    color: var(--main-color);
    text-align: center;
    font-size: 20px;
}
.pay-option-detail-title {
    text-align: center;
    font-size: 14px;
}

.pay-option-detail-block {

}
.pay-option-checkbox-block {
    display: flex;
    margin-bottom: 10px;
}
.pay-option-checkbox, .pay-option-checkbox-description {

}
.pay-option-checkbox {
    width: 30px;
    vertical-align: middle;
    padding-top: 3px;
}

.pay-option-detail-block {
    padding: 5px;
    display: table;
    min-height: 100px;
}
.pay-option-detail-block:first-child{
    padding-left: 0px;
}

.pay-option-detail-block:last-child {
    padding-right: 0px;
}
.pay-option-checkbox-description-title {
    font-size: 18px;
}
.pay-option-checkbox-description-subtitle {
    font-size: 12px;
}
.pay-option.active .pay-option-checkbox-description-title {
    color: var(--main-color);
}
.pay-option-checkbox-block-wrapper {
    padding-left: 0;
    padding-right: 0;
}
.pay-option-detail-block-data {
    background-color: var(--pay-option-detail-block-background-color);
    border: 1px solid var(--pay-option-detail-block-border-color);
    min-height: 100px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: table-cell;
    vertical-align: middle;
}
.pay-option-detail-block-data:last-child {
    padding-right: 0;
}
.total-savings {
    padding-left: 0;
}
.total-savings span {
    color: var(--main-color);
    text-align: center;
    font-size: 20px;
}

.text-main-color {
    color: var(--main-color);
}

input[type="text"].error-field,
input[type="password"].error-field,
input[type="number"].error-field
{
    border-color: red;
}
/* #892323 beet red */

.beets-wrapper {
  background-color: #fff;
}

.beets-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dbdada;
  height: 51px;
}
.beets-toolbar-text {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.bold-text {
  font-weight: 700;
}
.beets-toolbar-left {
  margin: 10px;
  display: flex;
  flex-direction: row;
}
.beets-toolbar-logo {
  background-image: url("../../assets/images/beets-coffee/header-logo.svg");
  width: 131px;
  height: 36px;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
}

.beets-toolbar-separator {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
}
.beets-toolbar-right {
  display: flex;
  align-items: center;
  align-content: center;
  margin: 10px;
}

.beets-hero {
  display: flex;
  flex-direction: column;
  background-color: #892323;
  height: 318px;
  margin-top: 25px;
  justify-content: center;
  align-content: center;
}

.beets-hero-image {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  background-image: url("../../assets/images/beets-coffee/logo.png");
  width: 100%;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
}

.beets-hero-text {
  color: #f4edd8;
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}
/* .beets-hero-bottom {
  align-self: flex-end;
} */
.beets-our-products {
  display: flex;
  flex-direction: row;
  height: 75px;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.our-products-line {
  width: 320px;
  border-top: 1px solid black;
  display: flex;
  align-items: center;
}
.our-products-text {
  text-align: center;
  white-space: nowrap;
  font-size: 28px;
  margin-left: 50px;
  margin-right: 50px;
}

.beets-subscription-section {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.beets-subscription-left {
  display: flex;
  justify-content: center;
  margin-top: 45px;
  margin-right: 70px;
  margin-left: 70px;
  margin-bottom: 20px;
  background-image: url("../../assets/images/beets-coffee/coffee-bag.svg");
  width: 375px;
  height: 329px;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 760px) {
  .beets-subscription-left {
    margin-left: 0;
    margin-right: 0;
  }
}
.beets-subscription-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F7F6F2;
  ;
  /* width: 651px;
  height: 404px;
  left: 693px;
  top: 524px; */
  padding: 30px 55px;
  margin: 40px 20px;

  background: #fcfbf7;
  box-shadow: 3px 5px 10px 2px rgba(0, 0, 0, 0.25);
}
@media (max-width: 760px) {
  .beets-subscription-right {
    margin: 0;
    padding: 30px 25px;
  }
}


.beets-subscription-title {
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 2px;
  text-align: center;
  /* font-family: Arial, Helvetica, sans-serif; */
}

.beets-subscription-subtitle {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 8px;
}

.beets-subscription-amount {
  font-size: 30px;
  font-weight: 500;
  margin-top: 5px;
}

.beets-tasting-notes {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.pay-button {
  background-color: #a58852;
  color: #fff;
  font-size: 24px;
  max-width: 540px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.pay-button:hover {
  cursor: pointer;
}
.guarantee {
  text-align: center;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  font-size: 16px;
}

.green-check {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  background-image: url("../../assets/images/beets-coffee/green-check.svg");
  width: 100%;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
}

.thanks-text {
  font-size: 34px;
  margin-bottom: 35px;
}


.logo-1 {
  background-image: url("../../assets/images/beets-coffee/logo-1.svg");
}
.logo-2 {
  background-image: url("../../assets/images/beets-coffee/logo-2.svg");
}
.logo-3 {
  background-image: url("../../assets/images/beets-coffee/logo-3.svg");
}
.beets-complete-section-text {
  font-size: 20px;
  margin-top: 15px;
}
.contact-text {
  margin: 100px 20px;
  font-size: 16px;
}

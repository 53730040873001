.beets-complete-wrapper {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.beets-complete-sections {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.beets-complete-section {
  max-width: 340px;
  min-width: 340px;
  margin: 20px;
}

.beets-complete-section-image {
  width: 100%;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
}